// const stage = process.env.VUE_APP_STAGE;
const serverURL = process.env.VUE_APP_APIG_API_DEV;

const config = {
  openapi: "3.0.1",
  info: {
    title: `tuango-api`,
    version: "1.1.1",
  },
  servers: [
    {
      url: `${serverURL}api/v1`,
      description: "Sandbox Reslynx",
    },
    {
      url: `${serverURL}tuango-dw`,
      description: "Tuango DW",
    },
  ],
  paths: {
    "/reslynx/rewards": {
      post: {
        summary:
          "Add Tuango Rewards to Reslynx booking. Use Sandbox Reslynx server.",
        tags: ["Reslynx"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                allOf: [{ $ref: "#/components/schemas/ReslynxBooking" }],
              },
            },
          },
        },
        security: [
          {
            oAuth2ClientCredentials: [],
          },
        ],
        responses: {
          201: {
            description:
              "Successfully added or deducted reward points. A Credit or Debit transaction was created. The response body contains the rewards details.",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ReslynxBooking",
                },
              },
            },
          },
          204: {
            description:
              "A Debit transaction was not created because no reward points were credited for this booking. No further information is provided in the response body.",
          },
          400: {
            description: "Bad request",
          },
          401: {
            $ref: "#/components/responses/AccessDeniedError",
          },
          409: {
            description: "Conflict, e.g., transaction already exists.",
          },
          422: {
            description: "Account doesn't exist or unsupported booking status.",
          },
          500: { $ref: "#/components/responses/ServerError" },
        },
      },
    },
    "/sales/reslynx": {
      post: {
        summary: "Add Reservation log. Use Tuango DW server.",
        tags: ["Reslynx"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                allOf: [
                  { $ref: "#/components/schemas/ReslynxBooking" },
                  { $ref: "#/components/schemas/RewardsDetails" },
                ],
              },
            },
          },
        },
        security: [
          {
            oAuth2ClientCredentials: [],
          },
        ],
        responses: {
          200: {
            description: "Data successfully processed.",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    message: {
                      type: "string",
                      example: "Success",
                    },
                  },
                },
              },
            },
          },
          400: {
            description: "Bad request",
          },
          500: { $ref: "#/components/responses/ServerError" },
        },
      },
    },
  },
  components: {
    securitySchemes: {
      oAuth2ClientCredentials: {
        type: "oauth2",
        flows: {
          clientCredentials: {
            tokenUrl: `https://auth-api-dev.tuango.ca/oauth2/token`,
            scopes: {
              [`https://apigw-dev.tuango.ca/api/RESLYNX`]: `API Access for Reslynx`,
            },
          },
        },
      },
    },
    responses: {
      AccessDeniedError: {
        description: "Access token is missing or invalid",
      },
      ServerError: {
        description: "Server error",
      },
    },
    schemas: {
      RewardsDetails: {
        type: "object",
        properties: {
          basePoints: {
            type: "integer",
            description: "The base points earned without the promotion.",
            example: 5000,
          },
          promotionCampaign: {
            type: "string",
            description: "The name of the promotion campaign.",
            example: "Test Promotion",
          },
          promotionPoints: {
            type: "integer",
            description: "The additional points earned through the promotion.",
            example: 5000,
          },
          promotionType: {
            type: "string",
            description: "The type of promotion applied.",
            enum: ["fixed", "multiplier"],
            example: "multiplier",
          },
          promotionValue: {
            type: "number",
            description:
              "The value of the promotion, e.g., the multiplier for a multiplier promotion.",
            example: 2,
          },
          points: {
            type: "integer",
            description:
              "The number of debited points if transaction was canceled.",
            example: 5000,
          },
        },
      },
      ReslynxBooking: {
        type: "object",
        properties: {
          billed_amount: {
            type: "number",
          },
          billing_address: {
            type: "string",
          },
          billing_city: {
            type: "string",
          },
          billing_country: {
            type: "string",
          },
          billing_email: {
            type: "string",
          },
          billing_firstname: {
            type: "string",
          },
          billing_lastname: {
            type: "string",
          },
          billing_phone: {
            type: "string",
          },
          billing_postalcode: {
            type: "string",
          },
          billing_province: {
            type: "string",
          },
          booking_date: {
            format: "date-time",
            type: "string",
          },
          booking_id: {
            type: "string",
          },
          booking_status: {
            enum: ["QT", "RQ", "HK", "CX", "ER", "QX"],
            type: "string",
          },
          cancellation_date: {
            format: "date-time",
            type: "string",
          },
          checkin: {
            format: "date-time",
            type: "string",
          },
          checkout: {
            format: "date-time",
            type: "string",
          },
          ficav: {
            type: "number",
          },
          hotel_code: {
            type: "string",
          },
          hotel_name: {
            type: "string",
          },
          num_night: {
            type: "number",
          },
          num_occupant: {
            type: "number",
          },
          num_room: {
            type: "number",
          },
          othertaxes: {
            type: "number",
          },
          package_id: {
            type: "string",
          },
          payment_processor: {
            type: "string",
          },
          payment_status: {
            type: "string",
          },
          price: {
            type: "number",
          },
          pricing_id: {
            type: "string",
          },
          pricing_name: {
            type: "string",
          },
          pricing_type: {
            type: "string",
          },
          promo_code: {
            type: "string",
          },
          promo_code_campaign: {
            type: "string",
          },
          promo_value: {
            type: "number",
          },
          supplier: {
            type: "string",
          },
          supplier_booking_id: {
            type: "string",
          },
          taxe_hebergement: {
            type: "number",
          },
          total_amount: {
            type: "number",
          },
          tps: {
            type: "number",
          },
          tuango_offer_id: {
            type: "string",
          },
          tvq: {
            type: "number",
          },
        },
        required: [
          "billed_amount",
          "billing_email",
          "booking_id",
          "booking_status",
        ],
      },
    },
  },
};

export default config;
