// const stage = process.env.VUE_APP_STAGE;
const serverURL = process.env.VUE_APP_APIG_API_DEV;
const serverDescription = "Sandbox";

const config = {
  openapi: "3.0.1",
  info: {
    title: `tuango-api`,
    version: "1.1.1",
  },
  servers: [
    {
      url: `${serverURL}api/mc/v1`,
      description: serverDescription,
    },
  ],
  tags: [
    {
      name: "Voucher",
      description: "Operations about vouchers",
    },
  ],
  paths: {
    "/redemptions/{voucher_id}": {
      post: {
        summary: "Redeem a voucher",
        tags: ["Voucher"],
        parameters: [
          {
            name: "voucher_id",
            in: "path",
            required: true,
            schema: {
              type: "string",
            },
          },
        ],
        security: [
          {
            oAuth2ClientCredentials: [],
          },
        ],
        responses: {
          200: {
            description: "Successful operation. Returns updated voucher data",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Voucher",
                },
              },
            },
          },
          401: {
            $ref: "#/components/responses/AccessDeniedError",
          },
          403: {
            $ref: "#/components/responses/VoucherUnauthorizedError",
          },
          404: {
            description: "Voucher not found",
          },
          422: {
            description:
              "Voucher that can not be redeemed (ex.: voucher status is not ACTIVE.)",
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
    },
    "/vouchers/{code}": {
      get: {
        summary: "Lookup for voucher by voucher code",
        tags: ["Voucher"],
        parameters: [
          {
            name: "code",
            in: "path",
            required: true,
            description: "Url encoded voucher code",
            schema: {
              type: "string",
            },
          },
        ],
        security: [
          {
            oAuth2ClientCredentials: [],
          },
        ],
        responses: {
          200: {
            description: "Successful operation. Returns voucher data",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Voucher",
                },
              },
            },
          },
          401: {
            $ref: "#/components/responses/AccessDeniedError",
          },
          403: {
            $ref: "#/components/responses/VoucherUnauthorizedError",
          },
          404: {
            description: "Voucher not found",
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
    },
    "/offers/{offer_id}": {
      get: {
        summary: "Lookup for offer by offer ID",
        tags: ["Offer"],
        parameters: [
          {
            name: "offer_id",
            in: "path",
            required: true,
            description: "Offer ID",
            schema: {
              type: "string",
            },
          },
        ],
        security: [
          {
            oAuth2ClientCredentials: [],
          },
        ],
        responses: {
          200: {
            description: "Successful operation. Returns offer data",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Offer",
                },
              },
            },
          },
          401: {
            $ref: "#/components/responses/AccessDeniedError",
          },
          404: {
            description: "Offer not found",
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
    },
    "/promocodes": {
      get: {
        summary: "Validate a promocode",
        tags: ["Promocode"],
        parameters: [
          {
            name: "code",
            in: "query",
            required: true,
            description: "Promo code",
            schema: {
              type: "string",
            },
          },
          {
            name: "offer_id",
            in: "query",
            required: true,
            description: "Offer ID",
            schema: {
              type: "string",
            },
          },
          {
            name: "amount",
            in: "query",
            required: true,
            description: "Order amount (in cents)",
            schema: {
              type: "string",
            },
          },
          {
            name: "qty",
            in: "query",
            required: false,
            description: "Quantity",
            schema: {
              type: "string",
              default: 1,
            },
          },
        ],
        security: [
          {
            oAuth2ClientCredentials: [],
          },
        ],
        responses: {
          200: {
            description:
              "Successful operation. Returns promo code validation details",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    status: {
                      type: "string",
                      enum: [
                        "VALID",
                        "INVALID",
                        "INACTIVE",
                        "MAX_USE",
                        "NOT_APPLICABLE",
                      ],
                    },
                    type: { type: "string", enum: ["dollar", "percentage"] },
                    value: {
                      type: "integer",
                      description: "Promo code value in cents",
                    },
                    details: { type: "string" },
                  },
                },
              },
            },
          },
          401: {
            $ref: "#/components/responses/AccessDeniedError",
          },
          400: {
            description: "Invalid request parameters",
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
      post: {
        summary: "Redeem a promocode",
        tags: ["Promocode"],
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                type: "object",
                required: ["code", "offer_id", "amount"],
                properties: {
                  code: {
                    type: "string",
                  },
                  offer_id: {
                    type: "string",
                  },
                  amount: {
                    type: "string",
                  },
                  qty: {
                    type: "string",
                  },
                },
              },
            },
          },
        },
        security: [
          {
            oAuth2ClientCredentials: [],
          },
        ],
        responses: {
          200: {
            description: "Successful operation. Returns promo code details",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    code: { type: "string" },
                    type: { type: "string", enum: ["dollar", "percentage"] },
                    value: {
                      type: "integer",
                      description: "Promo code value in cents",
                    },
                    currentUse: { type: "integer" },
                    maxUse: { type: "integer" },
                    updatedAt: { type: "integer" },
                  },
                },
              },
            },
          },
          401: {
            $ref: "#/components/responses/AccessDeniedError",
          },
          400: {
            description: "Invalid request parameters",
          },
          422: {
            description: "Invalid promocode",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    status: {
                      type: "string",
                      enum: [
                        "INVALID",
                        "INACTIVE",
                        "MAX_USE",
                        "NOT_APPLICABLE",
                      ],
                    },
                    type: { type: "string", enum: ["dollar", "percentage"] },
                    value: {
                      type: "integer",
                      description: "Promo code value in cents",
                    },
                    details: { type: "string" },
                  },
                },
              },
            },
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
    },
  },
  components: {
    securitySchemes: {
      oAuth2ClientCredentials: {
        type: "oauth2",
        flows: {
          clientCredentials: {
            tokenUrl: `https://auth-api-dev.tuango.ca/oauth2/token`,
            scopes: {
              [`https://apigw-dev.tuango.ca/api/mc/MC_API_ACCESS`]: `Tuango MC API Access`,
            },
          },
        },
      },
    },
    responses: {
      AccessDeniedError: {
        description: "Access token is missing or invalid",
      },
      VoucherUnauthorizedError: {
        description:
          "The client is not authorized to access the requested voucher",
      },
      ServerError: {
        description: "Server error",
      },
    },
    schemas: {
      Voucher: {
        type: "object",
        properties: {
          id: {
            type: "string",
            description: "Voucher ID",
          },
          offerTitle: {
            $ref: "#/components/schemas/MultilangText",
          },
          optionTitle: {
            $ref: "#/components/schemas/MultilangText",
          },
          whatYouGet: {
            $ref: "#/components/schemas/MultilangText",
          },
          offerDetails: {
            $ref: "#/components/schemas/MultilangText",
          },
          price: {
            type: "integer",
            description: "Price in cents",
          },
          value: {
            type: "integer",
            description: "Value in cents",
          },
          redemptionPeriod: {
            $ref: "#/components/schemas/DateRange",
          },
          orderDate: {
            type: "string",
            format: "date-time",
          },
          orderId: {
            type: "string",
          },
          clientName: {
            type: "string",
          },
          giftTo: {
            type: "string",
            nullable: true,
          },
          status: {
            type: "string",
            enum: ["ACTIVE", "CANCELED", "REDEEMED"],
          },
          redemptionDate: {
            type: "string",
            format: "date-time",
          },
        },
      },
      MultilangText: {
        type: "object",
        properties: {
          en: { type: "string" },
          fr: { type: "string" },
        },
      },
      DateRange: {
        type: "object",
        properties: {
          start: { type: "string", format: "date-time" },
          end: { type: "string", format: "date-time" },
        },
      },
      Offer: {
        type: "object",
        properties: {
          offerId: { type: "string" },
          offerTitle: {
            $ref: "#/components/schemas/MultilangText",
          },
          whatYouGet: {
            $ref: "#/components/schemas/MultilangText",
          },
          details: {
            $ref: "#/components/schemas/MultilangText",
          },
          description: {
            $ref: "#/components/schemas/MultilangText",
          },
          url: {
            $ref: "#/components/schemas/MultilangText",
          },
          images: {
            type: "object",
            properties: {
              fr: {
                type: "array",
                items: {
                  type: "string",
                },
              },
              en: {
                type: "array",
                items: {
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
};

export default config;
