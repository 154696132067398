// const stage = process.env.VUE_APP_STAGE;
const serverUrlDev = process.env.VUE_APP_APIG_API_DEV;
const serverUrlProd = process.env.VUE_APP_APIG_API_PROD;

const config = {
  openapi: "3.0.1",
  info: {
    title: `tuango-mobile-api`,
    version: "1.0.0",
  },
  servers: [
    {
      url: `${serverUrlDev}mobile-site-data`,
      description: "Dev",
    },
    {
      url: `${serverUrlProd}mobile-site-data`,
      description: "Production",
    },
  ],
  tags: [
    {
      name: "Tags",
      description: "Operations about tags and filters",
    },
    {
      name: "Offers",
      description: "Operations about offers",
    },
    {
      name: "Customers",
      description: "Operations about customers",
    },
  ],
  paths: {
    "/tags": {
      get: {
        summary: "Get list of mobile filters and tags",
        tags: ["Tags"],
        responses: {
          200: {
            description: "Successful operation. Returns filters and tags",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/FiltersResponse",
                },
              },
            },
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
        parameters: [
          {
            name: "Cache-Control",
            in: "header",
            description: "Invalidate cache if set to 'max-age=0'",
            required: false,
            schema: {
              type: "string",
            },
          },
        ],
      },
    },
    "/offers/{lang}/{offerId}": {
      get: {
        summary: "Get offer details by offer ID",
        tags: ["Offers"],
        parameters: [
          {
            name: "lang",
            in: "path",
            required: true,
            description: "Language code (either 'fr' or 'en')",
            schema: {
              type: "string",
              enum: ["fr", "en"],
            },
          },
          {
            name: "offerId",
            in: "path",
            required: true,
            description: "ID of the offer to fetch",
            schema: {
              type: "string",
            },
          },
          {
            name: "Cache-Control",
            in: "header",
            description: "Invalidate cache if set to 'max-age=0'",
            required: false,
            schema: {
              type: "string",
            },
          },
        ],
        responses: {
          200: {
            description: "Successful operation. Returns offer details",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/OfferResponse",
                },
              },
            },
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
    },
    "/customers": {
      post: {
        summary: "Fetch or create a customer",
        description:
          "Fetches an existing customer or creates a new one based on the provided data.",
        tags: ["Customers"],
        security: [
          {
            bearerAuth: [],
          },
        ],
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/CustomerRequest",
              },
            },
          },
        },
        responses: {
          200: {
            description: "Customer retrieved successfully.",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CustomerResponse",
                },
              },
            },
          },
          201: {
            description: "Customer created successfully.",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CustomerResponse",
                },
                examples: {
                  ActiveCustomer: {
                    summary: "Example of an ACTIVE customer",
                    value: {
                      status: "ACTIVE",
                      customer: {
                        id: "67890",
                        email: "jane.doe@example.com",
                        firstName: "Jane",
                        lastName: "Doe",
                      },
                    },
                  },
                  PendingCustomer: {
                    summary: "Example of a PENDING customer",
                    value: {
                      status: "PENDING",
                      message: "Please provide the missing attributes",
                      attributes: ["email", "postalCode"],
                    },
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/BadRequest",
          },
          401: {
            $ref: "#/components/responses/Unauthorized",
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
      patch: {
        summary: "Complete customer profile",
        description:
          "Updates customer profile with missing data. Only missing attributes will be updated. If user profile is complete, returns the existing customer data.",
        tags: ["Customers"],
        security: [
          {
            bearerAuth: [],
          },
        ],
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/CompleteProfileRequest",
              },
            },
          },
        },
        responses: {
          200: {
            description: "Customer profile updated successfully.",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CustomerResponse",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/BadRequest",
          },
          401: {
            $ref: "#/components/responses/Unauthorized",
          },
          404: {
            $ref: "#/components/responses/NotFound",
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
    },
  },
  components: {
    securitySchemes: {
      bearerAuth: {
        type: "http",
        scheme: "bearer",
        bearerFormat: "JWT",
        description: "JWT authorization token without 'Bearer '.",
      },
    },
    responses: {
      BadRequest: {
        description:
          "Bad Request. The server could not understand the request due to invalid syntax.",
        content: {
          "application/json": {
            schema: {
              $ref: "#/components/schemas/ErrorResponse",
            },
            example: {
              message: "Validation error: 'locale' is required.",
              code: 400,
            },
          },
        },
      },
      Unauthorized: {
        description:
          "Unauthorized. Authentication is required and has failed or has not yet been provided.",
        content: {
          "application/json": {
            schema: {
              $ref: "#/components/schemas/ErrorResponse",
            },
            example: {
              message: "Authorization token is missing or invalid.",
              code: 401,
            },
          },
        },
      },
      NotFound: {
        description: "Not Found. The requested resource could not be found.",
        content: {
          "application/json": {
            schema: {
              $ref: "#/components/schemas/ErrorResponse",
            },
            example: {
              message: "Customer not found.",
              code: 404,
            },
          },
        },
      },
      ServerError: {
        description:
          "Internal Server Error. An unexpected condition was encountered.",
        content: {
          "application/json": {
            schema: {
              $ref: "#/components/schemas/ErrorResponse",
            },
            example: {
              message: "An unexpected error occurred.",
              code: 500,
            },
          },
        },
      },
    },
    schemas: {
      FiltersResponse: {
        type: "object",
        properties: {
          filters: {
            type: "array",
            items: {
              $ref: "#/components/schemas/Filter",
            },
          },
          ts: {
            type: "integer",
            description: "Timestamp of the response",
            example: 1727100000000,
          },
        },
      },
      Filter: {
        type: "object",
        properties: {
          name: {
            type: "object",
            properties: {
              fr: {
                type: "string",
                example: "Exemple de filtre",
              },
              en: {
                type: "string",
                example: "Sample Filter",
              },
            },
          },
          index: {
            type: "integer",
            example: 0,
          },
          icon: {
            type: "string",
            format: "url",
            example: "https://example.com/sample-icon.jpg",
          },
          tags: {
            type: "array",
            items: {
              type: "string",
            },
            example: ["sample-tag"],
          },
        },
      },
      OfferResponse: {
        type: "object",
        properties: {
          allTimeRefundable: {
            type: "boolean",
            example: true,
          },
          buttonLabel: {
            type: "string",
            example: "View",
          },
          contentType: {
            type: "string",
            example: "Sample Content",
          },
          description: {
            type: "string",
            description: "Offer description",
            example: "<p>Sample offer description...</p>",
          },
          details: {
            type: "string",
            description: "Details of the offer",
            example: "<ul><li>Sample details...</li></ul>",
          },
          imagesMd: {
            type: "array",
            items: {
              type: "string",
              format: "url",
            },
            example: ["https://example.com/sample-md-image1.jpg"],
          },
          imagesLg: {
            type: "array",
            items: {
              type: "string",
              format: "url",
            },
            example: ["https://example.com/sample-lg-image1.jpg"],
          },
          hidePrice: {
            type: "boolean",
            example: false,
          },
          locations: {
            type: "array",
            items: {
              $ref: "#/components/schemas/Location",
            },
          },
          merchantName: {
            type: "string",
            example: "Sample Merchant",
          },
          merchants: {
            type: "array",
            items: {
              type: "string",
            },
            example: ["Sample Merchant Group"],
          },
          offerId: {
            type: "string",
            example: "12345",
          },
          offerType: {
            type: "string",
            example: "Voucher",
          },
          originalPrice: {
            type: "integer",
            example: 5000,
          },
          price: {
            type: "integer",
            example: 2500,
          },
          slug: {
            type: "string",
            example: "sample-offer-slug",
          },
          tags: {
            type: "array",
            items: {
              type: "object",
              properties: {
                slug: {
                  type: "string",
                },
                label: {
                  type: "string",
                },
              },
            },
            example: [{ slug: "sample-tag", label: "Sample Label" }],
          },
          title: {
            type: "string",
            example: "Sample offer title",
          },
          url: {
            type: "string",
            format: "url",
            example: "https://example.com/sample-offer",
          },
          whatYouGet: {
            type: "string",
            example: "<ul><li>Sample what you get...</li></ul>",
          },
          qtySold: {
            type: "integer",
            example: 100,
          },
          reviewsScore: {
            type: "number",
            format: "float",
            example: 4.5,
          },
          reviewsCount: {
            type: "integer",
            example: 300,
          },
          ts: {
            type: "integer",
            description: "Timestamp of the response",
            example: 1726779130821,
          },
        },
      },
      Location: {
        type: "object",
        properties: {
          country: {
            type: "string",
            example: "Sample Country",
          },
          locationName: {
            type: "string",
            example: "Sample Location Name",
          },
          address: {
            type: "string",
            example: "123 Sample St.",
          },
          city: {
            type: "string",
            example: "Sample City",
          },
          postalCode: {
            type: "string",
            example: "S1A 2B3",
          },
          geoloc: {
            type: "object",
            properties: {
              lng: {
                type: "number",
                example: -73.1234,
              },
              lat: {
                type: "number",
                example: 45.1234,
              },
            },
          },
          site: {
            type: "string",
            format: "url",
            example: "https://example.com/contact/",
          },
          phone: {
            type: "string",
            example: "123-456-7890",
          },
        },
      },
      CustomerRequest: {
        type: "object",
        required: ["locale", "trackingInfo"],
        properties: {
          locale: {
            type: "string",
            description: "User's locale (e.g., 'en', 'fr').",
          },
          trackingInfo: {
            $ref: "#/components/schemas/TrackingInfo",
          },
        },
      },
      TrackingInfo: {
        type: "object",
        required: ["mobile", "os", "appVersion"],
        properties: {
          mobile: {
            type: "boolean",
            default: true,
            description: "Indicates if the user is on a mobile device.",
          },
          os: {
            type: "string",
            enum: ["ios", "android"],
            description: "Operating system of the user's device.",
          },
          appVersion: {
            type: "string",
            description: "Version of the application.",
          },
        },
      },
      CustomerResponse: {
        type: "object",
        properties: {
          status: {
            type: "string",
            description: "Status of the customer account.",
            enum: ["ACTIVE", "PENDING"],
          },
          customer: {
            $ref: "#/components/schemas/PublicCustomerData",
          },
          message: {
            type: "string",
            description:
              "Additional information, usually present when status is 'PENDING'.",
          },
          attributes: {
            type: "array",
            items: {
              type: "string",
            },
            description:
              "List of missing attributes required to complete the profile.",
          },
        },
      },
      PublicCustomerData: {
        type: "object",
        properties: {
          id: {
            type: "string",
            description: "Customer's unique identifier.",
          },
          email: {
            type: "string",
            format: "email",
            description: "Customer's email address.",
          },
          firstName: {
            type: "string",
            description: "Customer's first name.",
          },
          lastName: {
            type: "string",
            description: "Customer's last name.",
          },
        },
      },
      CompleteProfileRequest: {
        type: "object",
        properties: {
          firstName: {
            type: "string",
            description: "Customer's first name.",
          },
          lastName: {
            type: "string",
            description: "Customer's last name.",
          },
          email: {
            type: "string",
            format: "email",
            description: "Customer's email address.",
          },
          postalCode: {
            type: "string",
            description: "Customer's postal code.",
          },
        },
        description:
          "Object containing the missing attributes required to complete the customer's profile.",
      },
      ErrorResponse: {
        type: "object",
        properties: {
          message: {
            type: "string",
            description: "Detailed error message.",
          },
          code: {
            type: "integer",
            description: "HTTP status code corresponding to the error.",
          },
        },
      },
    },
  },
};

export default config;
