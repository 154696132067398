<template>
  <div id="api-doc"></div>
</template>

<script setup>
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";

// @ is an alias to /src
import specReslynxDev from "@/apis/reslynx-dev.spec";
import specDev from "@/apis/mc-dev.spec";
import specProd from "@/apis/mc-prod.spec";
import specMobile from "@/apis/tuango-mobile.spec";
import { inject, nextTick, watch } from "vue";

const apiStage = inject("apiStage");

watch(
  apiStage,
  (stage) => {
    nextTick(() => {
      let spec;
      switch (stage) {
        case "dev-reslynx":
          spec = specReslynxDev;
          break;
        case "prod":
          spec = specProd;
          break;
        case "prod-mobile":
          spec = specMobile;
          break;
        default:
          spec = specDev;
      }

      SwaggerUI({
        dom_id: "#api-doc",
        spec,
      });
    });
  },
  {
    immediate: true,
  },
);
</script>

<style scoped></style>
